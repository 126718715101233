.comment {
  font-size: 20px;
  font-family: 'Work Sans', Arial, Helvetica, sans-serif;
  color: #4FC3A0;
}
h1.comment {
  font-size: 2em;
}
h2.comment {
  font-size: 1.8em;
}
h3.comment {
  font-size: 1.5em;
}
h4.comment {
  font-size: 1.1em;
}
.col-elem {
  margin: 20px 0;
}
.col-elem__desc {
  font-size: 20px;
  color: #4FC3A0;
}
/*# sourceMappingURL=mediprepare-theme-comments.css.map */