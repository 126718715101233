@fonts-dir: '../fonts';
@import 'font-faces/work-sans';
@color-primary: #4FC3A0;
@color-checkbox: #6d54ff;

.comment {
    font-size: 20px;
    font-family: 'Work Sans', Arial, Helvetica, sans-serif;
    color: @color-primary;
    h1& {
        font-size: 2em;
    }
    h2& {
        font-size: 1.8em;
    }
    h3& {
        font-size: 1.5em;
    }

    h4& {
        font-size: 1.1em;
    }
}

.col-elem {
    margin: 20px 0;
    &__desc {
        font-size: 20px;
        color: #4FC3A0;
    }
}

